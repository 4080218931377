import 'whatwg-fetch';
import '@babel/polyfill';
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
//import '../style/framework/vendors/line-awesome/css/line-awesome.css';
//import '../style/framework/vendors/fontawesome5/css/all.css';
import '../style/style.scss';
import * as Sentry from '@sentry/browser';

Sentry.init({dsn: SENTRY_DSN, environment: SENTRY_ENV});

class App extends Component {
  state = {
    email: '',
    password: '',
  };

  componentDidMount() {
    document.title = 'Login - Kelogs';
  }

  handleChangeEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  handleChangePassword = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  login = async (e) => {
    e.preventDefault();
    if (!this.state.email || this.state.email.indexOf('@') == -1) {
      alert('Please enter a valid email.');
      return;
    }
    if (!this.state.password) {
      alert('Please enter a valid password.');
      return;
    }

    const response = await fetch('/api/v1/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
      }),
    });

    const data = await response.json();
    if (!data.ok) {
      alert(data.message);
      return;
    }
    window.location.href = '/projects';
  };

  render() {
    return (
      <div className='d-flex flex-column flex-row-fluid wrapper'>
        <div className='content d-flex flex-column flex-column-fluid'>
          <div className='subheader py-6 py-lg-8 subheader-transparent'>
            <div className='container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'>
              <div className='d-flex align-items-center flex-wrap mr-1'>
                <div className='d-flex align-items-baseline flex-wrap mr-5'>
                  <h5 className='text-dark font-weight-bold my-1 mr-5'>
                    Login
                  </h5>
                </div>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='card card-custom'>
              <div className='card-header'>
                <div className='card-title'>
                  <h3 className='card-label'>
                    Login
                  </h3>
                </div>
              </div>
              <form className='form' onSubmit={this.login}>
                <div className='card-body'>
                  <div className='form-group'>
                    <label>Email</label>
                    <input className='form-control' type='text' placeholder='user@example.com' value={this.state.email} onChange={this.handleChangeEmail} />
                  </div>
                  <div className='form-group form-group-last'>
                    <label>Password</label>
                    <input className='form-control' type='password' placeholder='Password' value={this.state.password} onChange={this.handleChangePassword} />
                    <a className='font-size-sm font-weight-bold my-5' href='/forgot'>
                      Forgot password?
                    </a>
                  </div>
                </div>
                <div className='card-footer'>
                  <button className='btn btn-primary' onClick={this.login}>
                    Login
                  </button>
                  <span className='ml-10'>
                    or <a href='/signup'>Create an account</a>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className='footer bg-white py-4 d-flex flex-lg-column'>
          <div className='container d-flex flex-column flex-md-row align-items-center justify-content-between'>
            <div className='text-dark order-2 order-md-1'> {(new Date()).getFullYear()}&nbsp;&copy;&nbsp;Kelogs </div>

            <div className='nav nav-dark'>
              <a className='nav-link pl-0 pr-2' target='_blank' href='https://www.kelo.gs/wp-content/uploads/2019/08/TERMS-AND-CONDITIONS-KELOGS.pdf'>Terms of Service</a>
              <a className='nav-link pr-2' target='_blank' href='https://www.kelo.gs/wp-content/uploads/2019/08/LegalNoticesKelogs.pdf'>Legal Notices</a>
              <a className='nav-link pr-2' target='_blank' href='https://www.kelo.gs/wp-content/uploads/2019/08/PrivacyTerms.pdf'>Privacy Terms</a>
              <a className='nav-link pr-0' target='_blank' href='mailto:sales@kelo.gs'>Affiliation</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('root'));
